import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const productServices = {
    getBookmakers: (user, id) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiConst.product}/${id}/bookmakers`,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
};
