import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const sessionServices = {
    product: async (token, product) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiConst.session + "/product",
            headers: {'Authorization': 'Bearer ' + token},
            data: {
                token: token,
                product: product
            }
        };
        return await myAxios.request(config)
    }
};
