import React, {useEffect, useState} from 'react';
import {io} from 'socket.io-client';
import {WebSocketContext} from './WebSocketContext';

const WebSocketProvider = ({children}) => {
    const [socket, setSocket] = useState(null);
    const [user] = useState(localStorage.getItem('token'));

    useEffect(() => {
        if (!socket && user) {
            console.log('Socket connect', new Date().getTime());
            const ws = io(process.env.SOCKET_URI, {transports: ['websocket'], path: '/ws/socket.io'});
            setSocket(ws);
        } else if (!user && socket) {
            socket.close();
            setSocket(null);
        }
    }, [user]);

    return (
        <WebSocketContext.Provider value={{socket}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;

