import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const eventServices = {
    getCount: (user, from = null, to = null, bookmakers) => {
        return new Promise((resolve, reject) => {
            const dates = (from && to) ? `?from=${from}&to=${to}` : ''
            const bookmakersList = dates.length ? `&bookmakers=${JSON.stringify(bookmakers)}` : `?bookmakers=${JSON.stringify(bookmakers)}`
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: apiConst.event + '/count' + dates + bookmakersList,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getSearch: (user, query = '', from, to, bookmakers) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: apiConst.event + `/search/${query}`,
                data: {from, to, bookmakers},
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    /*
    getDropDownEvent: (id) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: id ? apiConst.event + '/' + id : apiConst.event,
                headers: {},
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getAllEruGoldBet: (id) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiConst.event}${apiConst.all}`,
                headers: {},
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },*/
};
