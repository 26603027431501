import {Button, Table, Tooltip} from 'antd';
import React from 'react';
import {dateFormatter, formatNumber} from '../../../utils';
import {CSVLink} from 'react-csv';
import {bookmakerServices} from "../../../api";

export const TournamentsMarketsCombination = ({
                                                  selectedTournamentId,
                                                  selectedMarket,
                                                  userBookmakers,
                                                  userBookmaker
                                              }) => {
    const [data, setDate] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [apiData, setApiData] = React.useState([]);
    const [user] = React.useState(localStorage.getItem('token'));

    React.useEffect(() => {

        async function loadData() {
            const response = await bookmakerServices.getTournamentMarket(user, selectedTournamentId, selectedMarket)
            const data = response.data.sort((a, b) => {
                const sorted = a.category.localeCompare(b.category)
                return sorted !== 0 ? sorted : a.tournament.localeCompare(b.tournament);
            })
            setApiData(data);
            mappedData(data)
        }

        const mappedData = (data) => {
            if (data.length === 0) return
            const referenceBookmakers = [];
            const selectedTournament = data.find(
                (i) => i._id === selectedTournamentId
            );
            // selectedTournament?.forEach((i) => {
            const bookmakers = Object.keys(selectedTournament.bookmakers);
            bookmakers.every((bookmaker) => {
                const market = selectedTournament.bookmakers[bookmaker];
                const selectedMarketName = (Object.keys(market)[0].includes('Basket') || Object.keys(market)[0].includes('Tennis')) ? selectedMarket : selectedMarket.toUpperCase()
                if (market[selectedMarketName]) {
                    Object.keys(market[selectedMarketName]).forEach(
                        (weekDay) => {
                            console.log(weekDay);
                            let referenceBookmakersObj = {};
                            userBookmakers.forEach((bookM) => {
                                const currentMarket = selectedTournament.bookmakers[bookM];
                                if (currentMarket && Object.keys(currentMarket).length > 0) {
                                    if (market[selectedMarketName][weekDay]) {
                                        referenceBookmakersObj = {
                                            ...referenceBookmakersObj,
                                            Reference:
                                                weekDay.split('')[0].toUpperCase() + weekDay.slice(1),
                                            [bookM.toUpperCase()]: [formatNumber(
                                                currentMarket[selectedMarketName][weekDay]
                                                    ?.value,
                                                1
                                            ), currentMarket[selectedMarketName][weekDay]
                                                ?.data]
                                        };
                                    }
                                } else {
                                    referenceBookmakersObj = {
                                        ...referenceBookmakersObj,
                                        Reference:
                                            weekDay.split('')[0].toUpperCase() + weekDay.slice(1),
                                        [bookM.toUpperCase()]: ["", ""],
                                    };
                                }
                            });
                            referenceBookmakers.push(referenceBookmakersObj);
                        }
                    );
                }
                return false;
            });
            // });
            setDate(referenceBookmakers);
            if (referenceBookmakers.length > 0) {
                setColumns((prev) =>
                    Object.keys(referenceBookmakers[0]).map((key, index) => ({
                        width: '90%',
                        title: <span
                            className={key.toLowerCase() === userBookmaker ? 'text-light' : 'text-secondary-emphasis'}>{key}</span>,
                        dataIndex: key,
                        render: (text) => (
                            index !== 0 ? (
                                    <Tooltip title={text ? text[1] ? "Updated at: " + dateFormatter(text[1]) : "" : ""}>
                                        <span>{text ? text[0] : ""}</span>
                                    </Tooltip>)
                                : (
                                    <span>{text ? text : ""}</span>
                                )
                        ),
                    }))
                );
            } else {
            }
        };
        loadData();
    }, [user, selectedMarket, selectedTournamentId]);

    const dataSource = React.useMemo(() => data, [data]);
    return (
        <>
            <div className='w-100 d-flex justify-content-center'>
                <CSVLink data={dataSource}
                         filename={`${selectedTournamentId}-${selectedMarket}-${new Date(Date.now()).toLocaleTimeString()}-${new Date(Date.now()).toLocaleDateString()}.csv`}>
                    <Button className='sidebet-export-button ml-2'>
                        Export as CSV
                    </Button>
                </CSVLink>
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
            />
        </>
    );
};
