import {Button, ConfigProvider, theme} from 'antd';
import React, {useState} from 'react';
import '../../App.css';
export const Home = () => {

    const [products] = useState(JSON.parse(localStorage.getItem('products') || "{}"))

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("bookmaker")
        localStorage.removeItem("products")
        localStorage.removeItem("user")
        window.location.reload();
    }

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    colorBgContainer: "#283038"
                }
            }}>
            <div className="container" data-bs-theme="dark">
                <div className='d-flex'>
                    <div className='w-25 d-flex'>
                        <h3 className="col-sm-12 col-md-5 px-2 sidebet-title">Home</h3></div>
                    <div className='w-75'>
                        <Button
                            className='sidebet-button float-right'
                            onClick={() => {
                                handleLogout()
                            }}
                        >
                            Logout
                        </Button>
                        <Button
                            disabled={!Object.keys(products).includes('fixtures')}
                            className='sidebet-button float-right mx-1'
                            onClick={() => {
                                window.location.href = '/fixtures'
                            }}
                        >
                            Fixtures Report
                        </Button>
                        <Button
                            disabled={!Object.keys(products).includes('oddvariations')}
                            className='sidebet-button float-right mx-1'
                            onClick={() => {
                                window.location.href = '/oddvariations'
                            }}
                        >
                            Odd Variations Report
                        </Button>
                        <Button
                            disabled={!Object.keys(products).includes('margin')}
                            className='sidebet-button float-right mx-1'
                            onClick={() => {
                                window.location.href = '/margin'
                            }}
                        >
                            Margin Report
                        </Button>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};
