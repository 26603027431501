import {Button, Table, Tooltip} from 'antd';
import React from 'react';
import {dateFormatter, formatNumber, marketsMap} from '../../../utils';
import {CSVLink} from 'react-csv';
import {bookmakerServices} from "../../../api";

export const MarketsBookmakersCombination = ({
                                                 selectedTournamentId,
                                                 selectedReference,
                                                 userBookmakers,
                                                 userBookmaker
                                             }) => {
    const [data, setDate] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [apiData, setApiData] = React.useState([]);
    const [user] = React.useState(localStorage.getItem('token'));

    React.useEffect(() => {
        async function loadData() {
            const response = await bookmakerServices.getTournamentReference(user, selectedTournamentId, selectedReference)
            const data = response.data.sort((a, b) => {
                const sorted = a.category.localeCompare(b.category)
                return sorted !== 0 ? sorted : a.tournament.localeCompare(b.tournament);
            })
            setApiData(data);
            mappedData(data)
        }

        const mappedData = (data) => {
            const marketsBookmakers = [];
            const selectedTournament = data.filter(
                (i) => i._id === selectedTournamentId
            );
            selectedTournament?.forEach((i) => {
                const bookmakers = Object.keys(i.bookmakers);
                bookmakers.every((bookmaker) => {
                    const markets = i.bookmakers[bookmaker];
                    Object.keys(markets).sort((a, b) => Object.keys(marketsMap).indexOf(a) - Object.keys(marketsMap).indexOf(b)).forEach((market) => {
                        Object.keys(markets[market]).forEach((weekDay) => {
                            const day = weekDay.toLowerCase();
                            if (selectedReference.toLowerCase() === day) {
                                let marketsBookmakersObj = {};
                                userBookmakers.forEach((bookM) => {
                                    if (i.bookmakers[bookM]) {
                                        if (i.bookmakers[bookM][market]) {
                                            if (i.bookmakers[bookM][market][day]) {
                                                marketsBookmakersObj = {
                                                    ...marketsBookmakersObj,
                                                    Market: marketsMap[market] || market,
                                                    [bookM.toUpperCase()]: [formatNumber(
                                                        i.bookmakers[bookM][market][day]?.value,
                                                        1), i.bookmakers[bookM][market][day]?.data]
                                                };
                                            }
                                        } else {
                                            marketsBookmakersObj = {
                                                ...marketsBookmakersObj,
                                                Market: marketsMap[market] || market,
                                                [bookM.toUpperCase()]: ["", ""],
                                            };
                                        }
                                    } else {
                                        marketsBookmakersObj = {
                                            ...marketsBookmakersObj,
                                            Market: marketsMap[market] || market,
                                            [bookM.toUpperCase()]: ["", ""],
                                        };
                                    }
                                });
                                marketsBookmakers.push(marketsBookmakersObj);
                            }
                        });
                    });
                    return false;
                });
            });
            setDate(marketsBookmakers);
            if (marketsBookmakers.length > 0) {
                setColumns((prev) =>
                    Object.keys(marketsBookmakers[0]).map((key, index) => ({
                        width: '90%',
                        title: <span
                            className={key.toLowerCase() === userBookmaker ? 'text-light' : 'text-secondary-emphasis'}>{key}</span>,
                        dataIndex: key,
                        render: (text) => (
                            index !== 0 ? (
                                    <Tooltip title={text ? text[1] ? "Updated at: " + dateFormatter(text[1]) : "" : ""}>
                                        <span>{text ? text[0] : ""}</span>
                                    </Tooltip>)
                                : (
                                    <span>{text ? text : ""}</span>
                                )
                        ),
                    }))
                );
            } else {
            }
        };
        loadData();
    }, [user, selectedReference, selectedTournamentId]);

    const dataSource = React.useMemo(() => data, [data]);
    return (
        <>
            <div className='w-100 d-flex justify-content-center'>
                <CSVLink data={dataSource}
                         filename={`${selectedTournamentId}-${selectedReference}-${new Date(Date.now()).toLocaleTimeString()}-${new Date(Date.now()).toLocaleDateString()}.csv`}>
                    <Button className='sidebet-export-button ml-2'>
                        Export as CSV
                    </Button>
                </CSVLink>
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{defaultPageSize: 20}}
            />
        </>
    );
};
