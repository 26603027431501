import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const bookmakerServices = {
    getAllTournaments: (user) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: apiConst.margin,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getTournamentReference: (user, t, r) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiConst.margin}?tournament=${t}&reference=${r.toLowerCase()}`,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getMarketReference: (user, m, r) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiConst.margin}?market=${m}&reference=${r.toLowerCase()}`,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getTournamentMarket: (user, t, m) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiConst.margin}?tournament=${t}&market=${m}`,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
};
