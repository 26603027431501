import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const noteServices = {
    getNote: async (tournamentId, token) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiConst.note + "/" + tournamentId,
            headers: {'Authorization': 'Bearer ' + token},
        };
        return await myAxios.request(config)
    },
    upsertNote: async (tournamentId, note, token) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiConst.note + "/" + tournamentId,
            data: {note: note},
            headers: {'Authorization': 'Bearer ' + token},
        };
        return await myAxios.request(config)
    },
    deleteNote: async (tournamentId, token) => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: apiConst.note + "/" + tournamentId,
            headers: {'Authorization': 'Bearer ' + token},
        };
        return await myAxios.request(config)
    },
};
