import React, {useRef, useState} from 'react';
import {noteServices} from "../../api/note";
import {dateFormatter} from "../../utils";

export const TooltipNote = ({
                                children,
                                tournamentId,
                                user,
                                notes,
                                setNotes,
                                openModal,
                                tournament
                            }) => {
    const [data, setData] = useState(null);
    const tipRef = useRef(null);

    const handleMouseEnter = () => {
        noteServices.getNote(tournamentId, user)
            .then((res) => {
                if (res.data.meta.status !== 404) {
                    showTooltip();
                    setData(res.data.data);
                    if (notes[tournamentId].count === 0) {
                        setNotes(Object.assign({}, notes, {[tournamentId]: {count: 1}}))
                    }
                } else {
                    hideTooltip();
                    setNotes(Object.assign({}, notes, {[tournamentId]: {count: 0}}))
                }
            })
            .catch(() => {
                hideTooltip();
                setNotes(Object.assign({}, notes, {[tournamentId]: {count: 0}}))
            });
    };

    const showTooltip = () => {
        if (tipRef.current) {
            tipRef.current.style.display = 'block';
        }
    };

    const hideTooltip = () => {
        if (tipRef.current) {
            tipRef.current.style.display = 'none';
        }
        setData(null);
    };

    return (
        <>
            <div
                className="text-end"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={hideTooltip}
                onClick={(e) => {
                    e.stopPropagation()
                    openModal(tournamentId, tournament)
                }}
            >
                {children}
            </div>
            <div
                className="border-2 border-margin-report odds-dark-bg text-white p-2 rounded-2 align-items-center m-0 w-75"
                style={{display: 'none', position: 'absolute', zIndex: 1100}}
                ref={tipRef}
            >
                <table className='w-100'>
                    <tbody>
                    <tr>
                        <td style={{fontSize: 'xx-small'}} className='text-sm-start px-1 py-0 m-0'>
                            {'Note:'}
                        </td>
                    </tr>
                    <tr style={{fontSize: 'smaller'}}>
                        <td className='text-sm-start px-1 py-0 m-0 pb-2'>
                            <b>{data?.note}</b>
                        </td>
                    </tr>
                    {data?.updatedAt && <tr className='m-0 p-0'>
                        <td style={{fontSize: 'xx-small'}} className='text-sm-start px-1 py-0 m-0'>{'Updated by '}<i
                            style={{fontSize: 'x-small'}}>{data?.updateUser}</i>{' at '}{dateFormatter(new Date(data?.updatedAt).getTime())}
                        </td>
                    </tr>}
                    <tr className='m-0 p-0'>
                        <td style={{fontSize: 'xx-small'}} className='text-sm-start px-1 py-0 m-0'>{'Created by '}<i
                            style={{fontSize: 'x-small'}}>{data?.insertUser}</i>{' at '}{dateFormatter(new Date(data?.createdAt).getTime())}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
