import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const userServices = {
    checkToken: async (token) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiConst.user + "/check",
            headers: {'Authorization': 'Bearer ' + token},
        };
        return await myAxios.request(config)
    },
    login: async (data) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiConst.user + "/login",
            data: data,
            headers: {},
        };
        return await myAxios.request(config)
    }
};
