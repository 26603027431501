export const apiConst = {
    event: '/user/event',
    all: '/user/all',
    margin: '/user/margin',
    tournament: '/user/tournament',
    user: '/user',
    product: '/user/product',
    session: '/user/session',
    note: 'user/note'
};
