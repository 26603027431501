import React, {Fragment, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Fixtures, Home, Login, Margin, OddVariations} from './pages';
import {userServices} from './api';
import {MainAppContext} from './utils';
import WebSocketProvider from "./components/websocket/WebSocketProvider";

function App() {

    const searchParams = new URLSearchParams(window.location.search);

    const [data, setData] = React.useState([]);
    const [dropDown, setDropDown] = React.useState([]);
    const [token, setToken] = useState(searchParams.get('token') ?? localStorage.getItem('token'));
    const [contentOnly] = useState(searchParams.get('token'))
    const [products] = useState(JSON.parse(localStorage.getItem('products') || "{}"))

    React.useEffect(() => {
        if (token !== null) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    React.useEffect(() => {
        if (token) {
            userServices.checkToken(token)
                .catch((err) => {
                    handleLogout()
                })
        }
    }, []);

    const handleLogout = () => {
        setToken(null)
        localStorage.removeItem("token");
        localStorage.removeItem("bookmaker")
        localStorage.removeItem("products")
        localStorage.removeItem("user")
    }

    return (
        <MainAppContext.Provider
            value={{
                data,
                dropDown,
            }}
        >
            <WebSocketProvider>
                <Router>
                    {token ?
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            {!contentOnly ? <Fragment>
                                    {products['margin'] && <Route path="/margin" element={<Margin contentOnly={contentOnly}/>}/>}
                                    {products['oddvariations'] && <Route path="/oddvariations" element={<OddVariations contentOnly={contentOnly}/>}/>}
                                    {products['fixtures'] && <Route path="/fixtures" element={<Fixtures contentOnly={contentOnly}/>}/>}
                                </Fragment>
                                :
                                <Fragment>
                                    {products['margin'] && <Route path="/:client/margin" element={<Margin contentOnly={contentOnly}/>}/>}
                                    {products['oddvariations'] && <Route path="/:client/oddvariations" element={<OddVariations contentOnly={contentOnly}/>}/>}
                                    {products['fixtures'] && <Route path="/:client/fixtures" element={<Fixtures contentOnly={contentOnly}/>}/>}
                                </Fragment>
                            }
                            <Route path="*" element={<Navigate to='/'/>}/>
                        </Routes>
                        :
                        <Routes>
                            <Route exact path="*" element={<Login setUser={setToken}/>}/>
                        </Routes>
                    }
                </Router>
            </WebSocketProvider>
        </MainAppContext.Provider>
    );
}

export default App;
