import {apiConst} from '../../utils';
import {myAxios} from '../axios';

export const tournamentServices = {
    getTournaments: (user) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: apiConst.tournament,
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getTree: (user) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: apiConst.tournament + '/tree',
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getAllTournaments: (user) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: apiConst.tournament + '/info',
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getEvents: (id, user, from = null, to = null, bookmakers) => {
        const baseUrl = apiConst.tournament + '/' + id + '/events'
        const dates = (from && to) ? `?from=${from}&to=${to}` : ''
        const bookmakersList = dates.length ? `&bookmakers=${JSON.stringify(bookmakers)}` : `?bookmakers=${JSON.stringify(bookmakers)}`
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: baseUrl + dates + bookmakersList,
                headers: {'Authorization': 'Bearer ' + user},
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getAllEvents: (id, user) => {
        const baseUrl = apiConst.tournament + '/' + id + '/all'
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: baseUrl,
                headers: {'Authorization': 'Bearer ' + user},
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getTournamentNotes: (user) => {
        return new Promise((resolve, reject) => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: apiConst.tournament + '/notes',
                headers: {'Authorization': 'Bearer ' + user}
            };
            myAxios
                .request(config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
};
