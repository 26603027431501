import {Fragment, useState} from "react";
import {userServices} from "../../api";

export function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const user = {
            email: email,
            password: password
        }
        await userServices.login(user).then(response => {
            if (localStorage.getItem("token") === null) {
                localStorage.setItem("token", response.data.data.token);
                if (response.data.data.bookmaker) {
                    localStorage.setItem("bookmaker", response.data.data.bookmaker);
                }
                if (response.data.data.products) {
                    localStorage.setItem("products", JSON.stringify(response.data.data.products));
                }
                localStorage.setItem("user", email)
                window.location.href = '/'
            }
        }).catch(e => {
            alert('Login failed')
            console.log(e)
        })
        setLoading(false)
    };

    return (
        <Fragment>
            {!loading ? (
                <div className="w-100 h-100">
                    <div className="container" data-bs-theme="dark">
                        <div className="row">
                            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                                <div className="card shadow sidebet-container my-5">
                                    <div className="card-body p-4 p-sm-5 login-card">
                                        <h4 className="card-title text-center mb-4 sidebet-title">
                                            Margin Report
                                        </h4>
                                        <h5 className="card-title text-center mb-5 fw-light fs-5">
                                            Sign In
                                        </h5>
                                        <form onSubmit={handleLogin}>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="floatingInput"
                                                    placeholder="name@example.com"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <label htmlFor="floatingInput">Email address</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="floatingPassword"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <label htmlFor="floatingPassword">Password</label>
                                            </div>
                                            <br></br>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="rememberPasswordCheck"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="rememberPasswordCheck"
                                                >
                                                    Remember password
                                                </label>
                                            </div>
                                            <br></br>
                                            <div className="d-grid">
                                                <button
                                                    className="btn btn-primary btn-login text-uppercase fw-bold"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    Sign in
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                </div>
            )}
        </Fragment>
    );
}

