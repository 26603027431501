import {Table, Tooltip} from 'antd';
import React from 'react';
import {dateFormatter, formatNumber, reference} from '../../../utils';
import {bookmakerServices} from "../../../api";

export const TournamentBookmakersCombination = ({
                                                    selectedMarket,
                                                    selectedReference,
                                                    userBookmakers,
                                                    userBookmaker,
                                                    selectedSport
                                                }) => {
    const [data, setDate] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [user] = React.useState(localStorage.getItem('token'));

    React.useEffect(() => {

        async function loadData() {
            const response = await bookmakerServices.getMarketReference(user, selectedMarket, selectedReference)
            const data = response.data.sort((a, b) => {
                const sorted = a.category.localeCompare(b.category)
                return sorted !== 0 ? sorted : a.tournament.localeCompare(b.tournament);
            })
            mappedData(data)
        }

        const mappedData = (data) => {
            const marketsBookmakers = [];

            data?.forEach((i) => {
                if (i.sportId.toString() !== selectedSport) return;
                const bookmakers = Object.keys(i.bookmakers);
                bookmakers.every((bookmaker) => {
                    const markets = i.bookmakers[bookmaker];
                    Object.keys(markets).forEach((market) => {
                        if (market.toLowerCase() === selectedMarket.toLowerCase()) {
                            Object.keys(reference).forEach((weekDay) => {
                                const day = weekDay.toLowerCase();
                                if (selectedReference.toLowerCase() === day) {
                                    let marketsBookmakersObj = {};
                                    userBookmakers.forEach((bookM) => {
                                        if (
                                            i.bookmakers?.[bookM]?.[market] &&
                                            i.bookmakers?.[bookM]?.[market]?.[day]
                                        ) {
                                            marketsBookmakersObj = {
                                                ...marketsBookmakersObj,
                                                Tournament: `${i.category} - ${i.tournament} `.concat(i.eurobetId ? `(${i.eurobetId})` : ''),
                                                [bookM.toUpperCase()]: [formatNumber(
                                                    i.bookmakers[bookM][selectedSport === '1' ? selectedMarket.toUpperCase() : selectedMarket][day]
                                                        ?.value,
                                                    1
                                                ), i.bookmakers[bookM][selectedSport === '1' ? selectedMarket.toUpperCase() : selectedMarket][day]
                                                    ?.data],
                                            };
                                        } else {
                                            marketsBookmakersObj = {
                                                ...marketsBookmakersObj,
                                                Tournament: `${i.category} - ${i.tournament} `.concat(i.eurobetId ? `(${i.eurobetId})` : ''),
                                                [bookM.toUpperCase()]: ["", ""],
                                            };
                                        }
                                    });
                                    marketsBookmakers.push(marketsBookmakersObj);
                                }
                            });
                        }
                    });
                    return false;
                });
            });

            setDate(marketsBookmakers);
            const marketsSets = new Set();
            marketsBookmakers.forEach((i) =>
                Object.keys(i).forEach((key) => marketsSets.add(key))
            );
            if (marketsBookmakers.length > 0) {
                setColumns(() =>
                    Array.from(marketsSets).map((key, index) => ({
                        width: '90%',
                        title: <span
                            className={key.toLowerCase() === userBookmaker ? 'text-light' : 'text-secondary-emphasis'}>{key}</span>,
                        dataIndex: key,
                        render: (text) => (
                            index !== 0 ? (
                                    <Tooltip title={text ? text[1] ? "Updated at: " + dateFormatter(text[1]) : "" : ""}>
                                        <span>{text ? text[0] : ""}</span>
                                    </Tooltip>)
                                : (
                                    <span>{text ? text : ""}</span>
                                )
                        ),
                    }))
                );
            } else {
            }
        };
        loadData();
    }, [user, selectedReference, selectedMarket]);

    const dataSource = React.useMemo(() => data, [data]);
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['20', '50', '100']}}
        />
    );
};