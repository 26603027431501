import moment from "moment";

export const formatNumber = (number, round) =>
    number ? Number.parseFloat(number).toFixed(round || 2) : '';

export const dateFormatter = (timestamp) => {
    return moment.unix(timestamp / 1000).format("DD/MM/YYYY HH:mm")
}

export const dateFormatterNoYear = (timestamp) => {
    return moment.unix(timestamp / 1000).format("DD/MM HH:mm")
}

export const hoursFormatter = (timestamp) => {
    return moment.unix(timestamp).format("HH:mm:ss")
}
