import React, {useEffect, useRef, useState} from 'react';


export const TooltipFixtures = ({children, data, userBookmaker}) => {

    const [mouseOver, setMouseOver] = useState(false);
    const tipRef = useRef(null);
    let scroll = document.getElementById('fixtures-sidebar')?.scrollTop || 0

    useEffect(() => {
        if (!mouseOver) {
            hideTooltip();
        }
    }, [mouseOver])

    const handleMouseEnter = () => {
        setMouseOver(true)
        showTooltip()
    }

    const showTooltip = () => {
        scroll = document.getElementById('fixtures-sidebar')?.scrollTop || 0
        if (tipRef.current) {
            tipRef.current.style.display = 'block';
            tipRef.current.style.position = 'fixed';
            tipRef.current.style.marginLeft = "50px";
            tipRef.current.style.marginTop = `${-scroll * 2}px`
            tipRef.current.style.zIndex = '1100';
        }
    }

    const hideTooltip = () => {
        if (tipRef.current) {
            tipRef.current.style.display = 'none';
            tipRef.current.style.marginLeft = "0px";
            tipRef.current.style.zIndex = '0';
        }
    }

    function handleMouseLeave() {
        setMouseOver(false)
        hideTooltip()
    }

    return <>
        <div
            className="d-flex align-items-center"
        >
            <div
                className={"border-2 border-margin-report odds-dark-bg text-white p-2 rounded-2 align-items-center"}
                style={{display: 'none'}}
                ref={tipRef}
            >
                <table className='w-100 '>
                    <tbody>
                    <tr className='m-0 p-0'>
                        <td className={`text-sm-start px-1 py-0 m-0`}>
                            <b>{userBookmaker}</b>
                        </td>
                        <td className={`text-sm-end px-1 py-0 m-0`}>
                            {data[userBookmaker] ? <b>{data[userBookmaker]}</b> : <b>0</b>}
                        </td>
                    </tr>
                    {Object.entries(data).filter(([book, num]) => book !== userBookmaker).map(([book, num]) => {
                        return <>
                            <tr className='m-0 p-0'>
                                <td className={`text-sm-start px-1 py-0 m-0`}>
                                    {book}
                                </td>
                                <td className={`text-sm-end px-1 py-0 m-0`}>
                                    <b>{num}</b>
                                </td>
                            </tr>
                        </>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="text-end"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >{children}</div>
    </>
}